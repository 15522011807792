import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { HapNotificationComponent } from '../components/hap-notification/hap-notification.component';

export type HapNotification = ActiveToast<HapNotificationComponent>;

@Injectable({ providedIn: 'root' })
export class NotificationService {
   constructor(private toastr: ToastrService) {
      NotificationService.instance = this;
   }

   public static instance: NotificationService;
   private infoDuration = 3000;
   private errorDuration = 5000;
   private actionDuration = 10000;

   /** autocloses after 5 or 10 seconds (if has actions) */
   public error(message: string, config?: Partial<IndividualConfig>, ...actions: string[]): HapNotification {
      const t = this.toastr.error(message, null, {
         timeOut: actions.length ? this.actionDuration : this.errorDuration,
         ...config
      });

      t.toastRef.componentInstance.actions = [...actions.filter((el) => !!el)];
      return t;
   }

   /** autocloses after 3 or 10 seconds (if has actions) */
   public info(message: string, config?: Partial<IndividualConfig>, ...actions: string[]): HapNotification {
      const t = this.toastr.info(message, null, {
         timeOut: actions.length ? this.actionDuration : this.infoDuration,
         ...config
      });

      t.toastRef.componentInstance.actions = [...actions.filter((el) => !!el)];
      return t;
   }

   /** does not autoclose or close on tap, uses info style */
   public open(message: string, config?: Partial<IndividualConfig>, ...actions: string[]): HapNotification {
      const t = this.toastr.info(message, null, {
         disableTimeOut: true,
         tapToDismiss: false,
         ...config
      });

      t.toastRef.componentInstance.actions = [...actions.filter((el) => !!el)];
      return t;
   }

   public dismissAll() {
      this.toastr.clear();
   }
}
