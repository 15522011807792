import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, firstValueFrom } from 'rxjs';
import {
   CapabilityDto,
   ConfigurationAppConfigurationDto,
   ConfigurationSetCreateRequestDto,
   ConfigurationSetDto,
   ConfigurationSettingPutRequestDto,
   ConfigurationStructureDto,
   PartnerDto,
   SignalROptionsDto
} from '../model/dtos';
import { CapabilityType, ConfigurationTarget } from '../model/enums';
import { BaseConfigProvider } from './base-config-provider.service';
import { FeatureToggle } from '../model/feature-toggle.enum';

export class UserConfigDto {
   public configurations: any[];
   public organizationSettings?: any;
   public intercomIdentityVerificationToken?: string;
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
   public readonly baseUrl: string;
   public baseHapticAppUrl: string;
   public signalRUrl: string;
   public filesUrl: string;

   public apiUtil: ApiUtil;
   public intercomAppId: string;
   public capabilities: CapabilityDto[];

   public signalR: SignalROptionsDto;
   public partner?: PartnerDto;

   private onConfigChangeSource = new ReplaySubject<ConfigService>(1);
   public readonly onConfigChange$: Observable<ConfigService> = this.onConfigChangeSource.asObservable();

   public userConfiguration: UserConfigDto;

   constructor(
      private configProvider: BaseConfigProvider,
      public http: HttpClient
   ) {
      this.baseUrl = window.location.origin + '/';
      this.apiUtil = new ApiUtil(this);
   }

   public getUserConfig(): Promise<any> {
      const url = `${this.baseApiUrl}configs/client/user`;
      return firstValueFrom(this.http.get<CapabilityDto[]>(url));
   }

   public async fetchAuthenticatedUserSettings() {
      this.userConfiguration = await this.getUserConfig();
      this.capabilities = await firstValueFrom(this.getUserCapabilities());

      this.onConfigChangeSource.next(this);
   }

   public get hasInternetAccess() {
      return this.environment.hasInternetAccess;
   }

   public get releaseVersion() {
      return this.configProvider.version;
   }

   public get roleName() {
      return 'Admin';
   }

   public get clientId() {
      return `Alleo${this.roleName}-v${this.releaseVersion}`;
   }

   public get environment(): Environment {
      if (this.configProvider.globalConfig?.environment) return new Environment(this.configProvider.globalConfig.environment);
      return new Environment(this.configProvider.environment);
   }

   public get baseApiUrl(): string {
      return this.configProvider.baseApiUrl;
   }

   public featureEnabled(key: FeatureToggle | string): boolean {
      if (!key || !this.capabilities) return false;

      const capTypes = [
         CapabilityType.AppFeatures,
         CapabilityType.BoardFeatures,
         CapabilityType.BoardMeetingRoomFeatures,
         CapabilityType.BoardReadOnlyFeatures
      ];

      const capability = this.capabilities.find((c) => c.key === key && capTypes.includes(c.type));
      return capability?.isEnabled;
   }

   public async initialize() {
      const config = this.configProvider.globalConfig;
      this.filesUrl = config.endpoints.filesUrl;
      this.signalRUrl = config.endpoints.signalRUrl;
      this.baseHapticAppUrl = config.endpoints.appUrl;

      this.signalR = config.signalR;
      this.partner = config.partner;

      if (config.intercom) {
         this.intercomAppId = config.intercom.appId;
      }

      await this.fetchCapabilities();
   }

   private async fetchCapabilities() {
      this.capabilities = await firstValueFrom(this.getCapabilitiesDtos());
      this.onConfigChangeSource.next(this);
   }

   public getUserCapabilities(): Observable<CapabilityDto[]> {
      const url = `${this.baseApiUrl}capabilities/client/user`;
      return this.http.get<CapabilityDto[]>(url);
   }

   public getCapabilitiesDtos(): Observable<CapabilityDto[]> {
      const url = `${this.baseApiUrl}capabilities/client/global`;
      return this.http.get<CapabilityDto[]>(url);
   }

   public putConfiguration(setId: string, configId: string, dto: ConfigurationSettingPutRequestDto): Observable<any> {
      return this.http.put(`${this.baseApiUrl}configs/sets/${setId}/configurations/${configId}`, dto);
   }

   public deleteConfiguration(setId: string, configId: string): Observable<any> {
      return this.http.delete(`${this.baseApiUrl}configs/sets/${setId}/configurations/${configId}`);
   }

   public getSetByTarget(target: ConfigurationTarget, targetId: string): Observable<ConfigurationSetDto> {
      return this.http.get<ConfigurationSetDto>(`${this.baseApiUrl}configs/sets/?target=${target}&targetId=${targetId}`);
   }

   public createSet(dto: ConfigurationSetCreateRequestDto): Observable<any> {
      return this.http.post(`${this.baseApiUrl}configs/sets/`, dto);
   }

   public deleteSet(setId: string): Observable<any> {
      return this.http.delete(`${this.baseApiUrl}configs/sets/${setId}/`);
   }

   public getConfigurations(target: ConfigurationTarget, targetId: string = null): Observable<ConfigurationStructureDto> {
      const url = `${this.baseApiUrl}configs/?target=${target}`;
      if (targetId) return this.http.get<ConfigurationStructureDto>(`${url}&targetId=${targetId}`);

      return this.http.get<ConfigurationStructureDto>(url);
   }

   public getConfigurationsConfiguration(): Observable<ConfigurationAppConfigurationDto[]> {
      const url = `${this.baseApiUrl}configs/configuration`;
      return this.http.get<ConfigurationAppConfigurationDto[]>(url);
   }
}

export class Environment {
   public readonly name: string;
   public readonly infrastructure: string;
   public readonly hasInternetAccess: boolean;
   public readonly useSingleSignOn: boolean;

   constructor(environment?: Partial<Environment>) {
      Object.assign(this, environment);
   }

   public get isOnPremise() {
      return this.infrastructure === 'OnPremise';
   }

   public get isAzurePublic() {
      return this.infrastructure === 'AzurePublic';
   }

   public get isAzurePrivate() {
      return this.infrastructure === 'AzurePrivate';
   }

   public get isDevelopment() {
      return this.infrastructure === 'Development';
   }

   public get isProd() {
      return this.name === 'Production';
   }

   public get isLocal() {
      return this.name === 'Local';
   }
}

export class ApiUtil {
   constructor(private configService: ConfigService) {}

   public isProxyRequest(request: HttpRequest<any>): boolean {
      if (!this.isHapticApiRequest(request)) return false;
      return new URL(request.url).pathname.indexOf('/v1/proxy') === 0;
   }

   public isHapticFileRequest(request: HttpRequest<any>): boolean {
      return this.isHapticFileUrl(request.url);
   }

   public isLoginRequest(request: HttpRequest<any>): boolean {
      if (!this.isHapticApiRequest(request)) return false;
      return new URL(request.url).pathname.indexOf('/v1/auth/login') === 0;
   }

   public isHapticApiRequest(request: HttpRequest<any>): boolean {
      return this.isHapticApiUrl(request.url);
   }

   public isHapticFileUrl(url: string): boolean {
      return url?.indexOf(this.configService.filesUrl) === 0;
   }

   public isHapticApiUrl(url: string) {
      return url?.indexOf(this.configService.baseApiUrl) === 0;
   }
}
