import { Injectable } from '@angular/core';
import { FeatureToggle } from '../../model/feature-toggle.enum';
import Utils from '../../utils';
import { ConfigService } from '../../services/config.service';
import { Debounce } from '../Utilities/decorators';

@Injectable({ providedIn: 'root' })
export class IntercomService {
   private appId: string;

   public messengerOpen = false;

   private markupAdded = false;
   private hideButton = false;

   constructor(public config: ConfigService) {
      this.config.onConfigChange$.subscribe(() => this.addMarkup());
   }

   public async addMarkup() {
      if (
         !this.config.hasInternetAccess ||
         !this.config.featureEnabled(FeatureToggle.SupportChat) ||
         window.top !== window.self ||
         this.appId ||
         !this.config.intercomAppId ||
         this.markupAdded ||
         Utils.isMobile()
      )
         return;

      this.markupAdded = true;

      this.appId = this.config.intercomAppId;

      window.intercomSettings = {
         app_id: this.appId
      };

      // inercom embed code changed not not wait for onload event since we're
      // triggering this manually after the app has been loaded
      (function (k) {
         const w = window;
         const ic = w.Intercom;
         if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
         } else {
            const d = document;
            const i = function (...args) {
               i.c(args);
            };
            i.q = [];
            i.c = function (args) {
               i.q.push(args);
            };
            w.Intercom = i;

            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/' + k;
            d.body.appendChild(s);
         }
      })(this.appId);

      if (await this.ready()) {
         window.Intercom('onShow', () => {
            this.messengerOpen = true;
         });

         window.Intercom('onHide', () => {
            this.messengerOpen = false;
         });
      } else {
         console.warn('Intercom failed to boot');
      }
   }

   public async ready() {
      if (window.Intercom) return true;

      if (this.config.hasInternetAccess && this.config.featureEnabled(FeatureToggle.SupportChat)) {
         await Utils.waitUntil(() => window.Intercom, 10, 60 * 1000);
         if (window.Intercom) return true;
      }

      return false;
   }

   public async identifyInviteToken(token: string) {
      if (await this.ready()) window.Intercom('update', { invite_token: token });
   }

   public async identifyUser(email: string, name: string, user_hash: string) {
      if (await this.ready()) {
         window.Intercom('update', { email, name, user_hash });
      }
   }

   public async identifyBoard(boardId: string) {
      if (await this.ready()) window.Intercom('update', { boardId });
   }

   public async startTour(tourId: number) {
      if (await this.ready()) window.Intercom('startTour', tourId);
   }

   public async shutdown() {
      if (await this.ready()) {
         this.hideButton = false;
         this.update();
         window.Intercom('shutdown');
      }
   }

   public async trackEvent(event: string, payload?: any) {
      if (await this.ready()) {
         window.Intercom('trackEvent', event, payload);

         this.updateDebounced();
      }
   }

   public async showNewMessage(msg?: string) {
      if (await this.ready()) window.Intercom('showNewMessage', msg);
   }

   public async hide() {
      if (await this.ready()) window.Intercom('hide');
   }

   public async show() {
      if (await this.ready()) window.Intercom('show');
   }

   public async hideChatButton() {
      if (await this.ready()) {
         this.hideButton = true;
         this.update();
      }
   }

   public async showChatButton() {
      if (await this.ready()) {
         this.hideButton = false;
         this.update();
      }
   }

   public async boot() {
      if (await this.ready()) {
         this.update();
         window.Intercom('boot');
      }
   }

   @Debounce(1000)
   public updateDebounced() {
      this.update();
   }

   public async update() {
      if (await this.ready()) {
         window.Intercom('update', {
            hide_default_launcher: this.hideButton
         });
      }
   }

   public async setVerticalPadding(n: number) {
      if (await this.ready()) {
         window.Intercom('update', {
            vertical_padding: 20 + n
         });
      }
   }

   public async restart() {
      await this.shutdown();
      await this.boot();
   }

   public get isOpen() {
      return !this.hideButton;
   }
}
