import { Injectable } from '@angular/core';
import { Environment } from './config.service';

@Injectable({ providedIn: 'root' })
export class BaseConfigProvider {
   public environment: Environment;
   public baseApiUrl: string;
   public globalConfig: any;
   public version: any;
}
