import { DebounceSettings, ThrottleSettings, debounce, throttle } from 'lodash';

export function Debounce(limit: number, opts: DebounceSettings = { leading: false, trailing: true }) {
   return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      const originalMethod = descriptor.value;
      descriptor.value = debounce(originalMethod, limit, opts);
      return descriptor;
   };
}

export function Throttle(limit: number, opts: ThrottleSettings = { leading: true, trailing: false }) {
   return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      const originalMethod = descriptor.value;
      descriptor.value = throttle(originalMethod, limit, opts);
      return descriptor;
   };
}
